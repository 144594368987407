@import '~@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
@import 'app/shared/styles/mixins';
@import 'app/shared/styles/form';
@import 'app/shared/styles/button';
@import 'app/shared/styles/sticky-header';
@import 'app/shared/styles/sidenav';

global-footer {
  display: block;
  padding: 40px 25px 25px;
}

global-nav {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.cdk-global-scrollblock {
  position: static !important;
  overflow: hidden !important;
}

lib-ngx-deeplinker {
  button {
    background-color: #fff;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 6px 10px !important;
  }
}

.mat-table {
  background: none !important;
}

.main-container {
  input:focus,
  input:active,
  input:hover,
  select:focus,
  select:active,
  select:hover,
  textarea:focus,
  textarea:active,
  textarea:hover {
    border: none;
  }
}

.separator {
  width: 96%;
  border-top: 1px solid #ddd;
  margin: 20px 0px;
}

.alert {
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
}

.list-container::-webkit-scrollbar {
  width: 8px;
}

.list-container::-webkit-scrollbar-track {
  border-radius: 30px;
}

.list-container::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: var(--primary-color);
}
