.x-btn-primary,
.x-btn-secondary {
  padding: 0 14px;
  margin: unset;
}

@include x-icon-button;
.mat-stroked-button {
  border: 1px solid !important;
}

.wide-btn {
  padding: 0 30px !important;
}

.tooltip-btn-wrapper {
  display: inline-block;
}
.mat-tooltip {
  background: rgba(0, 0, 0, 0.7);
  font-size: 13px;
}

.spinner-container {
  margin: 25px auto 25px;
  width: 60px;
  height: 60px;
}

.dialog-primary,
.dialog-secondary {
  padding: 0 14px;
}
.dialog-primary {
  margin-left: 1rem;
}

.checkbox-section {
  display: flex;
  align-content: center;
  align-items: center;
  margin-right: 5px;
}
