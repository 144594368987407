@use '@angular/material' as mat;

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$font: var(--font);

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, $font, -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, $font, -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, $font, 0em),
  display-1: mat.define-typography-level(34px, 40px, 400, $font, 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, $font, 0em),
  title: mat.define-typography-level(20px, 32px, 500, $font, 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, $font, 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, $font, 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, $font, 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, $font, 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, $font, 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, $font, 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, $font, 1.5px),
);

@include mat.core($fontConfig);

.main-container {
  --text-primary-color: #{white};
  --text-primary-lighter-color: #{rgba(black, 0.87)};
  --text-primary-darker-color: #{white};
  --text-accent-color: #{white};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{white};

  --text-warn-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --text-warn-darker-color: #{white};
}
$theme-colors: (
  main: var(--primary-color),
  lighter: var(--primary-lighter-color),
  darker: var(--primary-darker-color),
  200: var(--primary-color),
  contrast: (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  ),
);
$primary-palette: mat.define-palette($theme-colors, main, lighter, darker);

$mat-warn: (
  main: #c32b2a,
  lighter: #ffe6ae,
  darker: #c32b2a,
  200: #c32b2a,
  contrast: (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  ),
);

$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$primary-theme: mat.define-light-theme($primary-palette, $primary-palette, $mat-warn);
@include mat.all-component-themes($primary-theme);

.mat-expansion-panel-header .mat-content {
  display: inline-block;
  width: 100%;
}

.mat-expansion-panel-header-description,
.mat-expansion-panel-header-title {
  flex-flow: row;
  justify-content: space-between;
}

.mat-expansion-panel .mat-expansion-panel-header.mat-expansion-toggle-indicator-before {
  align-items: flex-start;
  padding: 15px 19px 15px 24px;
  .mat-expansion-indicator {
    margin: -2px 16px 0 0;
    display: inline-block;
    vertical-align: top;
    &::after {
      content: '\231F';
      display: inline-block;
      transform: rotate(45deg);
      vertical-align: top;
      font-size: 30px;
      line-height: 30px;
      border: none;
    }
  }
}

.mat-form-field-disabled .mat-form-field-outline {
  background: #f6f6f6;
}
